
.components-mobile__header__star {
  display: none;
}

.components-mobile__header__star > .row {
  margin-bottom: 0px;
}

.components-mobile__header__star .menu-label {
  color: #bdbdbd;
  font-size: 0.9em;
  font-weight: 900;
  letter-spacing: 1px;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.components-mobile__header__star .menu-label:not(:first-child) {
  margin-top: 20px;
}

.components-mobile__header__star .menu-list a {
  display: block;
  padding: 5px 0px;
  margin-bottom: 0px;
}

.components-mobile__header__star .menu-list a img {
  vertical-align: middle;
  margin-right: 1em;
}

.components-mobile__header__star .divider {
  margin-left: -25px;
  margin-right: -25px;
  border-top: 2px solid #eceff1;
}

.components-mobile__header__star .divider:last-child {
  display: none;
}

.components-mobile__header__star .responsive-img {
  /*height: 48px;*/
  /*width: 48px;*/
}

@media only screen and (max-width: 992px) {
  .components-mobile__header__star h5 {
    color: #f34e43;
    font-size: 1rem;
    text-transform: uppercase;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .components-mobile__header__star .responsive-img {
    /*max-height: 41px;*/
  }
}

/*

 */


#mobile-my-favourites .menu-label {
  color: #bdbdbd;
  font-size: 0.9em;
  font-weight: 900;
  letter-spacing: 1px;
  margin-bottom: 5px;
  text-transform: uppercase;
}

#mobile-my-favourites .menu-label:not(:first-child) {
  margin-top: 20px;
}

#mobile-my-favourites .menu-list a {
  display: block;
  padding: 5px 0px;
}

#mobile-my-favourites .menu-list a img {
  vertical-align: middle;
  margin-right: 1em;
}

#mobile-my-favourites .divider {
  margin-left: -25px;
  margin-right: -25px;
  border-top: 2px solid #eceff1;
}

#mobile-my-favourites .divider:last-child {
  display: none;
}

#mobile-my-favourites .responsive-img {
  height: 48px;
  width: 48px;
}

@media only screen and (max-width: 992px) {
  #mobile-my-favourites h5 {
    color: #f34e43;
    font-size: 1rem;
    text-transform: uppercase;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  #mobile-my-favourites .responsive-img {
    max-height: 41px;
  }
}
